import React from "react";
import { useSelector } from "react-redux";

import { CATEGORIES, SUBCATEGORIES } from "Constants/Categories";

import { useProduct } from "Hooks/useProduct";
import { useProductCombos } from "Hooks/useProductCombos";
import { useRegions } from "Hooks/useRegions";

import { Store } from "Reducers/interface";

import { hasPathInArray } from "Utils/Arrays";
import validatePermissions from "Utils/validatePermissions";

import { Button } from "Components/Button";
import { ErrorMessage } from "Components/ErrorMessage";
import { LoadingText } from "Components/LoadingText";

import { ViewProductProps } from "./interface";
import { ProductAnamnesis } from "./ProductAnamnesis";
import { ProductCategory } from "./ProductCategory";
import { ProductComposition } from "./ProductComposition";
import { ProductDescription } from "./ProductDescription";
import { ProductFeatured } from "./ProductFeatured";
import { ProductIdentification } from "./ProductIdentification";
import { ProductPrepare } from "./ProductPrepare";
import { ProductRestriction } from "./ProductRestriction";
import { ProductStatus } from "./ProductStatus";
import * as S from "./styles";
import { mountAdditionalProductData } from "./utils";

export const ViewProduct = ({ closeModal, idSelected }: ViewProductProps) => {
  const {
    product: productInfo,
    loadingProduct,
    productError,
  } = useProduct(idSelected);

  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const { combos } = useProductCombos({ includeCombo: ["*"] });

  const { regions } = useRegions();

  const productAdditionalInfo = mountAdditionalProductData(
    productInfo,
    combos,
    regions
  );

  const product = {
    ...productInfo,
    ...productAdditionalInfo,
  };

  const { category, id } = product;

  const productSubcategoriesList = product.subcategories?.map((cat) => cat.id);

  const showEditButtons = Number(category.id) !== CATEGORIES.vaccine;

  return (
    <S.Container>
      {loadingProduct && (
        <LoadingText text="Carregando dados do produto selecionado" />
      )}

      {productError && (
        <ErrorMessage text="Ocorreu um erro ao buscar o produto desejado" />
      )}

      {productInfo && !loadingProduct && (
        <>
          {showEditButtons ? (
            <div className="buttons-action">
              {validatePermissions("Update Products", user_permissions) && (
                <Button borderRadius="111">
                  <S.LinkTo to={`/produto/${id}/editar`}>EDITAR</S.LinkTo>
                </Button>
              )}

              <Button borderRadius="111">
                <S.LinkTo to={`/produto/duplicar/${id}`}>
                  Duplicar Produto
                </S.LinkTo>
              </Button>

              <Button textButton onClick={closeModal}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#000"
                  />
                </svg>
              </Button>
            </div>
          ) : null}

          <h2>Detalhes do produto</h2>

          <div className="content-holder">
            <ProductStatus product={product} />

            <ProductCategory product={product} />

            <ProductIdentification product={product} />

            {hasPathInArray([1, 2, 3, 4, 8], category?.id) && (
              <ProductComposition product={product} />
            )}

            {productSubcategoriesList?.includes(
              Number(SUBCATEGORIES.vaccine_club)
            ) && <ProductFeatured product={product} />}

            <ProductDescription product={product} />

            <ProductRestriction product={product} />

            <ProductPrepare product={product} />

            <ProductAnamnesis product={product} />
          </div>
        </>
      )}
    </S.Container>
  );
};
