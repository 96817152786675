import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";

import { createProduct, updateProduct } from "Requests/Products";

import { useProduct } from "Hooks/useProduct";
import { useProductCombos } from "Hooks/useProductCombos";
import { useRegions } from "Hooks/useRegions";

import { validateArrayType } from "Utils/Arrays";
import { FakePromise } from "Utils/Promise";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { ErrorMessage } from "Components/ErrorMessage";
import { LoadingText } from "Components/LoadingText";
import PageHeader from "Components/Pages/Header";
import Breadcrumb from "Components/Shared/Breadcrumb";

import { CATEGORIES } from "Constants/Categories";
import * as S from "../CreateProduct/styles";
import { useModal } from "../Modals/useModal";
import { ModalAction } from "../Modals/Warnings";
import { ProductForm } from "../ProductForm";
import { FormValuesProps } from "../ProductForm/interface";
import {
  createInitialValues,
  formatCreateProduct,
  getInitialUnitsValue,
  formatUnitsRequest,
} from "../ProductForm/utils";

export const EditProduct = () => {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const params = useParams<{ productId: string; duplicar?: string }>();
  const productId = Number(params.productId);
  const history = useHistory();
  const { product, loadingProduct, productError } = useProduct(productId);
  const { combosError } = useProductCombos({
    excludeCombo: ["categories", "subcategories"],
    includeCombo: undefined,
  });
  const { regions } = useRegions();

  const {
    modalConfirmEditing,
    modalOptions,
    modalNonValueProduct,
    openConfirmModal,
  } = useModal();

  const isDuplicatingProduct = Boolean(params?.duplicar);

  const INITIAL_VALUES = useMemo(
    () => ({
      ...createInitialValues(product),
      units: getInitialUnitsValue(product?.units, regions),
    }),
    [product?.id]
  );

  const handleSubmit = async (values: FormValuesProps) => {
    try {
      const productUpdateInfos = formatCreateProduct(values);

      if (!productUpdateInfos) return;

      const productInfo = {
        ...productUpdateInfos,
        units: {
          ...formatUnitsRequest(values.units, regions),
        },
      };

      const hasProductWithZeroValue =
        productInfo?.partners &&
        productInfo?.partners?.some((partner) => partner.price === 0);

      setLoadingCreate(true);

      if (hasProductWithZeroValue) await modalNonValueProduct();

      if (isDuplicatingProduct) {
        await createProduct(productInfo);
        createToast("success", "Produto criado com sucesso");
      }

      if (!isDuplicatingProduct) {
        await modalConfirmEditing();
        await updateProduct(productId, productInfo);
        createToast("success", "Produto atualizado com sucesso");
      }

      setLoadingCreate(false);
      await FakePromise(2000);
      history.push("/produtos");
    } catch (error: any) {
      setLoadingCreate(false);

      if (error === "form editing canceled") {
        createToast("error", "Edição do produto cancelada");
        return;
      }

      const { errors } = error.response.data;

      if (validateArrayType(errors)) {
        errors.forEach((err: { message: string }) => {
          createToast("error", err.message);
        });
        return;
      }
    }
  };

  const showEditButtons = Number(product?.category.id) !== CATEGORIES.vaccine;

  if (productError) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <ErrorMessage text={productError} />
          </main>
        </div>
      </S.Container>
    );
  }

  if (loadingProduct) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <LoadingText text="Carregando lista de categorias" />
          </main>
        </div>
      </S.Container>
    );
  }

  if (combosError) {
    return (
      <S.Container className="entry-app-content">
        <div className="content-holder">
          <main>
            <ErrorMessage text={combosError} />
          </main>
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container className="entry-app-content">
      <div className="content-holder">
        <div>
          <div className="wrapper-header-content">
            <PageHeader title="Editar produto">
              <Breadcrumb
                list={[
                  {
                    path: "/produtos",
                    label: "Produtos",
                    current: false,
                  },
                  {
                    path: "/produtos",
                    label: "Edição produto",
                    current: true,
                  },
                ]}
              />
            </PageHeader>

            {showEditButtons ? (
              <div className="buttons-container">
                <>
                  <Button
                    outlined
                    borderRadius="111"
                    onClick={() => history.push("/produtos")}
                    disabled={loadingCreate}
                  >
                    Cancelar
                  </Button>
                  <Button
                    form="form-submit"
                    type="submit"
                    borderRadius="111"
                    disabled={loadingCreate}
                  >
                    Salvar ajustes
                  </Button>
                </>
              </div>
            ) : null}
          </div>
        </div>

        {openConfirmModal && (
          <ModalAction
            modalEditOptions={modalOptions}
            modalOpened={openConfirmModal}
          />
        )}

        <main>
          {loadingCreate && (
            <LoadingText
              mgBottom={50}
              flex
              text="Editando produto. Aguarde!"
              imgSize={15}
            />
          )}
          <ProductForm
            handleSubmit={handleSubmit}
            initialValues={INITIAL_VALUES}
          />
        </main>
      </div>
    </S.Container>
  );
};
