import React from "react";

import CheckupsIcon from "Assets/Images/checkups.svg";
import ExamsIcon from "Assets/Images/exams.png";
import ImageIcon from "Assets/Images/images.svg";
import InfusionIcon from "Assets/Images/infusion.svg";
import TelemedicineIcon from "Assets/Images/telemedicin.svg";
import TestIcon from "Assets/Images/tests.svg";
import VaccineIcon from "Assets/Images/vaccine_rounded_icon.svg";

import { CATEGORIES } from "Constants/Categories";
import { SelectProductCategoryPros } from "./interface";
import * as S from "./styles";

export const SelectProductCategory = ({
  categoriesList,
  selectCategory,
}: SelectProductCategoryPros) => {
  const getCategoryIcon = (id: number) => {
    switch (id) {
      case 1:
        return ExamsIcon;
      case 2:
        return CheckupsIcon;
      case 3:
        return TestIcon;
      case 4:
        return VaccineIcon;
      case 5:
        return TelemedicineIcon;
      case 6:
        return VaccineIcon;
      case 7:
        return ImageIcon;
      case 8:
        return InfusionIcon;
      default:
        return "";
    }
  };

  const productsCategories = categoriesList?.filter(
    (category) => category.id !== CATEGORIES.vaccine
  );

  return (
    <S.Container>
      <h2>Selecione o tipo de produto</h2>
      <div className="categories-container">
        {productsCategories?.map((category) => (
          <button
            key={category.category}
            onClick={() => selectCategory(category)}
          >
            <div>
              <div className="image-container">
                <img
                  src={getCategoryIcon(category.id)}
                  alt={category.category}
                />
              </div>
              <span>{category.category}</span>
            </div>
          </button>
        ))}
      </div>
    </S.Container>
  );
};
