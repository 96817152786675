import { CATEGORIES } from "Constants/Categories";

import IconExame from "Assets/Images/icon-exams.svg";
import IconImages from "Assets/Images/imagesFoundGray.svg";
import IconInfusion from "Assets/Images/infusionFounGray.svg";
import MedicalPlan from "Assets/Images/medical-plan.png";
import PrivateMedicalPlan from "Assets/Images/private-medical-plan.png";
import HomeCollect from "Assets/Images/type-of-collect-home.png";
import UnitCollect from "Assets/Images/type-of-collect-unit.png";

export const initialStateMessages = {
  success: "",
  wait: "",
  error: "",
};

export const typesApplication = [
  {
    id: "INTRADÉRMICA",
    name: "INTRADÉRMICA",
  },
  {
    id: "INTRAMUSCULAR",
    name: "INTRAMUSCULAR",
  },
  {
    id: "SUBCUTÂNEA",
    name: "SUBCUTÂNEA",
  },
  {
    id: "VIA ORAL",
    name: "VIA ORAL",
  },
  {
    id: "MISTA",
    name: "MISTA",
  },
];

export const statusProduct = [
  {
    id: "0",
    name: "INDISPONÍVEL",
  },
  {
    id: "1",
    name: "DISPONÍVEL",
  },
  {
    id: "2",
    name: "LISTA DE ESPERA",
  },
];

export const statusProductCart = [
  {
    id: "1",
    name: "DISPONÍVEL",
  },
  {
    id: "2",
    name: "LISTA DE ESPERA",
  },
];

export const doseQuantity = [
  {
    id: 1,
    name: "DOSE ANUAL",
  },
  {
    id: 2,
    name: "DOSE ÚNICA",
  },
  {
    id: 3,
    name: "2 DOSES",
  },
  {
    id: 4,
    name: "2 DOSES + ANUAL",
  },
  {
    id: 5,
    name: "3 DOSES",
  },
  {
    id: 6,
    name: "3 DOSES + DECENAIS",
  },
  {
    id: 7,
    name: "2 A 3 DOSES",
  },
  {
    id: 8,
    name: "2 A 4 DOSES",
  },
  {
    id: 9,
    name: "4 DOSES",
  },
  {
    id: 10,
    name: "DOSE ANUAL",
  },
  {
    id: 11,
    name: "DOSE ÚNICA",
  },
  {
    id: 12,
    name: "2 DOSES",
  },
  {
    id: 13,
    name: "2 DOSES + ANUAL",
  },
  {
    id: 14,
    name: "3 DOSES",
  },
  {
    id: 15,
    name: "3 DOSES + DECENAIS",
  },
  {
    id: 16,
    name: "2 A 3 DOSES",
  },
  {
    id: 17,
    name: "2 A 4 DOSES",
  },
  {
    id: 18,
    name: "4 DOSES",
  },
];

export const collects = [
  {
    id: "AMBOS",
    name: "AMBOS",
  },
  {
    id: "UNIDADE",
    name: "UNIDADE",
  },
  {
    id: "DOMICILIAR",
    name: "DOMICILIAR",
  },
];

export const resultTypesOptions = [
  {
    id: 4,
    result_type: "Abaixo dos Valores de Referência",
  },
  {
    id: 5,
    result_type: "Dentro dos Valores Normais de Referência",
  },
  {
    id: 6,
    result_type: "Acima dos Valores Normais de Referência",
  },
  {
    id: 16,
    result_type: "Positivo",
  },
  {
    id: 17,
    result_type: "Negativo",
  },
];

export const typeService = [
  { id: "", name: "Tipo de atendimento" },
  { id: "1", name: "Unidade" },
  { id: "2", name: "Domiciliar" },
];
export const arrayOfServices = [
  {
    id: 1,
    image: IconExame,
    text: "Exames, Testes, Check-ups",
    categories_included: [CATEGORIES.exam, CATEGORIES.checkup, CATEGORIES.test],
  },
  {
    id: 2,
    image: IconImages,
    text: "Imagens",
    categories_included: [CATEGORIES.image],
  },
  {
    id: 3,
    image: IconInfusion,
    text: "Infusões",
    categories_included: [CATEGORIES.infusion],
  },
];

export const comboPreCollections = [
  {
    collection: "Trazer exames anteriores",
    id: 1,
  },
  {
    collection: (
      <span>
        Não urinar por no mínimo{" "}
        <span style={{ color: "transparent" }}>--------------</span> h antes do
        exame
      </span>
    ),
    id: 2,
  },
];

export const budgetServiceList = [
  {
    id: 1,
    image: PrivateMedicalPlan,
    text: "Particular",
    service: "private",
  },
  {
    id: 2,
    image: MedicalPlan,
    text: "Convênio",
    service: "covenant",
  },
];

export const docType = [
  { id: "cpf", type: "CPF" },
  { id: "passport", type: "PASSAPORTE" },
];
export const typeOfBudget = [
  {
    id: 1,
    image: UnitCollect,
    text: "Unidade Labi",
    service: "unity",
    disabled: true,
    free_visit: false,
    message_disabled:
      "O convênio selecionado não permite atendimento na unidade",
  },
  {
    id: 2,
    image: HomeCollect,
    text: "Labi em casa",
    service: "home",
    disabled: true,
    free_visit: false,
    message_disabled: "O convênio selecionado não cobre o valor domiciliar",
  },
];
